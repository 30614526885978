import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import image from '../../../public/images/homepage/oceania.jpeg';
import { ContinentSuggestionData } from '../../types/homePageTypes';
import AhotuLink from './AhotuLink';
import { Heading } from './Heading';
import { Paragraph } from './Paragraph';

interface IContinentCardProps {
  continent: ContinentSuggestionData;
  width: number;
  id: string;
}

export type continent = {
  name: string;
  count: number;
  slug: string;
};

export const ContinentCard = ({ continent, width, id }: IContinentCardProps) => {
  const { t } = useTranslation();

  return (
    <AhotuLink href={continent.suggestion.url}>
      <div id={id} className="my-3 cursor-pointer" style={{ width: width }}>
        <div className="relative" style={{ width: width, height: width }}>
          {continent?.image?.key && (
            <Image
              style={{ objectFit: 'cover' }}
              fill
              sizes="(max-width: 640px) 66vw, (max-width: 1280px) 50vw, (max-width: 1691px) 33vw, (max-width: 1800px) 25vw, 33vw"
              alt={
                continent?.image.altText ? continent?.image.altText : `This is an image of ${continent.suggestion.name}`
              }
              placeholder="blur"
              src={`https://images.ahotu.com/${continent?.image?.key}`}
              blurDataURL={continent?.image.base64}
            />
          )}
          {!continent?.image?.key && (
            <Image
              style={{ objectFit: 'cover' }}
              fill
              src={image}
              alt="fallback image"
              //loader={StaticImageLoader}
            />
          )}
        </div>
        <div className="my-4">
          <Heading headingLevel="h3" className="mb-2 " size="base">
            {continent.suggestion.name}
          </Heading>
          <Paragraph size="p1">
            {`${continent.suggestion.docCount.toLocaleString()} ${t('common:event', {
              count: continent.suggestion.docCount
            })}`}
          </Paragraph>
        </div>
      </div>
    </AhotuLink>
  );
};
