import Image from 'next/image';
import { useRouter } from 'next/router';

import { translateWithFallback } from '~/src/gql-queries/translateWithFallback';

import { getLocalizedProperty } from '../../../lib/localization';
import { SpotlightsType } from '../../../types/homePageTypes';
import { Heading } from '../../atoms/Heading';
import { LinkButton } from '../../atoms/LinkButton';
import { Paragraph } from '../../atoms/Paragraph';

interface ISpotlightProps {
  spotlight: SpotlightsType;
}

const Spotlight = ({ spotlight }: ISpotlightProps) => {
  const { locale } = useRouter();

  const { description = '', eventName = '' } = translateWithFallback(locale, spotlight.translations);

  return (
    <div className="w-full md:w-1/2">
      <div className="relative h-[80vw] sm:h-[40vw] 4xl:h-[20vw] w-full bg-fit bg-center">
        <Image src={`${spotlight.image}`} fill className="object-cover" alt={`Image of ${eventName}`} />
      </div>
      <div className="my-6">
        <Paragraph className="mb-2" size="p2">
          {getLocalizedProperty(spotlight.activityType, locale)}
        </Paragraph>
        <Heading className="m-0 line-clamp-1 text-2xl lg:text-3xl" headingLevel="h2">
          {eventName}
        </Heading>
        <div className="flex flex-row mt-4">
          <Paragraph className="mr-12" size="p2">
            {getLocalizedProperty(spotlight.location, locale)}
          </Paragraph>
          <Paragraph size="p2">{getLocalizedProperty(spotlight.date, locale)}</Paragraph>
        </div>
        <Paragraph className="line-clamp-2">{description}</Paragraph>
        <LinkButton
          fullwidth={false}
          className="mt-2 w-fit px-6"
          variant="stroke"
          type="button"
          text={getLocalizedProperty(spotlight.button.text, locale)}
          href={spotlight.button.href}
        />
      </div>
    </div>
  );
};

export default Spotlight;
